<template>
    <div>
    <v-card :loading="loading">
      <!-- Descrição -->
      <div>
        <h2>Descrição:</h2>
        <p class="text-justify">Selecione as competências técnicas considerando que estão
          atribuidas diretamente ao cargo do colaborador, logo se quiser que o modelo
          avalie determinada classe de cargos escolha apenas as competências relacionadas.
        </p>
      </div>
      <!-- Competências -->
      <div>
        <v-autocomplete
          v-model="competenciasSelected"
          :items="competencias"
          outlined
          dense
          chips
          small-chips
          label="Competências"
          multiple
          @blur="exibirComportamentos()"
        >
          <template v-slot:selection="data">
            <template>
              <v-chip>
                <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
                <v-list-item-content>
                  {{ data.item.nome }}
                </v-list-item-content>
              </v-chip>
            </template>
          </template>
          <template v-slot:item="data">
            <template>
              <v-icon class="pr-3">{{ data.item.icone }}</v-icon>
              <v-list-item-content>
                {{ data.item.nome }}
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <div class="mb-8">
          <h3 style="color: rgba(0, 0, 0, 0.54)">Previsualização</h3>
          <v-divider></v-divider>
        </div>
        <div>
          <div class="mb-4">
            <h4 style="color: rgba(0, 0, 0, 0.54)">
              Autoavaliação - Como você se avalia no comportamento:
            </h4>
            <v-card
              v-for="comportamento in comportamentosSelected" :key="comportamento.id"
              style="background-color: white !important"
              elevation="4"
              class="ma-2"
            >
              <v-container>
                {{ comportamento.descricao }}
                <v-slider
                  class="mx-2"
                  hide-details
                  v-model="range"
                  disabled
                  step="1"
                  min="1"
                  max="10"
                  thumb-label
                  ticks
                  persistent-hint
                  track-color="orange darken-3"
                ></v-slider>
              </v-container>
            </v-card>
          </div>
          <div class="mb-4">
            <h4 style="color: rgba(0, 0, 0, 0.54)">
              Avaliação de Pares - Como você avalia seu colega NOME no
              comportamento:
            </h4>
            <v-card
              v-for="comportamento in comportamentosSelected" :key="comportamento.id"
              style="background-color: white !important"
              elevation="4"
              class="ma-2"
            >
              <v-container>
                {{ comportamento.descricao }}
                <v-slider
                  class="mx-2"
                  hide-details
                  v-model="range"
                  disabled
                  step="1"
                  min="1"
                  max="10"
                  thumb-label
                  ticks
                  persistent-hint
                  track-color="orange darken-3"
                ></v-slider>
              </v-container>
            </v-card>
          </div>
          <div class="mb-4">
            <h4 style="color: rgba(0, 0, 0, 0.54)">
              Avaliação de Líder - Como você avalia seu líder NOME no
              comportamento:
            </h4>
            <v-card
              v-for="comportamento in comportamentosSelected" :key="comportamento.id"
              style="background-color: white !important"
              elevation="4"
              class="ma-2"
            >
              <v-container>
                {{ comportamento.descricao }}
                <v-slider
                  class="mx-2"
                  hide-details
                  v-model="range"
                  disabled
                  step="1"
                  min="1"
                  max="10"
                  thumb-label
                  ticks
                  persistent-hint
                  track-color="orange darken-3"
                ></v-slider>
              </v-container>
            </v-card>
          </div>
          <div class="mb-4">
            <h4 style="color: rgba(0, 0, 0, 0.54)">
              Avaliação de Cliente - Como você avalia seu cliente NOME
              no comportamento:
            </h4>
            <v-card
              v-for="comportamento in comportamentosSelected" :key="comportamento.id"
              style="background-color: white !important"
              elevation="4"
              class="ma-2"
            >
              <v-container>
                {{ comportamento.descricao }}
                <v-slider
                  class="mx-2"
                  hide-details
                  v-model="range"
                  disabled
                  step="1"
                  min="1"
                  max="10"
                  thumb-label
                  ticks
                  persistent-hint
                  track-color="orange darken-3"
                ></v-slider>
              </v-container>
            </v-card>
          </div>
          <div class="mb-4">
            <h4 style="color: rgba(0, 0, 0, 0.54)">
              Avaliação de Subordinados - Como você avalia seu
              subordinado NOME no comportamento:
            </h4>
            <v-card
              v-for="comportamento in comportamentosSelected" :key="comportamento.id"
              style="background-color: white !important"
              elevation="4"
              class="ma-2"
            >
              <v-container>
                {{ comportamento.descricao }}
                <v-slider
                  class="mx-2"
                  hide-details
                  v-model="range"
                  disabled
                  step="1"
                  min="1"
                  max="10"
                  thumb-label
                  ticks
                  persistent-hint
                  track-color="orange darken-3"
                ></v-slider>
              </v-container>
            </v-card>
          </div>
        </div>
      </div>
    </v-card>
    <!-- Botões de Continuar e Voltar -->
    <div class="d-flex flex-column flex-sm-row mt-10">
      <v-btn color="primary" @click="finalizar()"> Finalizar </v-btn>
      <v-btn text class="mx-3" @click="voltar()"> Voltar </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvdTecnica",
  data() {
    return {
      competenciasSelected: [],
      comportamentosSelected: [],
      range: 5,
      ticksLabels: [1,2,3,4,5,6,7,8,9,10],
      redirecionamento: false
    }
  },
  created() {
    if(this.competencias.length === 0) {
      this.$store.dispatch("avd/buscarAvdTecnica",this.$route.params.uuid);
    }
    if(this.AvdTecnica.length > 0) {
      this.competenciasSelected = this.competenciasSelect;
      this.comportamentosSelected = this.AvdTecnica;
    }
  },
  watch:{
    loading(){
      if(this.competencias.length > 0 && this.loading === false) {
        if(this.redirecionamento) this.$router.go(-1)
        this.redirecionamento = true;
      }
    }
  },
  computed: {
    modeloSelecionado: {
      get() {
        return this.$store.getters["avd/getModeloSelecionado"];
      }
    },
    AvdComportamental: {
      get() {
        return this.$store.getters["avd/getAvdComportamental"];
      }
    },
    AvdTecnica: {
      get() {
        return this.$store.getters["avd/getAvdTecnica"];
      },
      set(value) {
        return value;
      }
    },
    comportamentos: {
      get() {
        return this.$store.getters["avd/getComportamentosTecnicas"];
      }
    },
    competencias: {
      get() {
        return this.$store.getters["avd/getCompetenciasTecnicas"];
      }
    },
    competenciasSelect: {
      get() {
        return this.$store.getters["avd/getCompetenciasTecnicasSelect"];
      },
      set(value) {
        return value;
      }
    },
    loading: {
      get() {
        return this.$store.getters["avd/getLoading"];
      },
      set(value) {
        return value;
      }
    },
  },
  methods: {
    exibirComportamentos() {
      this.comportamentosSelected = [];
      this.comportamentos.forEach(comportamento => {
        if(this.competenciasSelected.some(competencia => comportamento.competencia_select_id_nome_icone.id === competencia.id)) {
          this.comportamentosSelected.push(comportamento);
        }
      });
    },
    voltar() {
      this.$store.dispatch('avd/resetAvdTecnicaData');
      this.competenciasSelected.forEach(c => this.competenciasSelect.push(c))
      this.comportamentosSelected.forEach(c => this.AvdTecnica.push(c))
      this.$emit('decrease');
    },
    finalizar() {
      this.$store.dispatch('avd/resetAvdTecnicaData');
      this.competenciasSelected.forEach(c => this.competenciasSelect.push(c))
      this.comportamentosSelected.forEach(c => this.AvdTecnica.push(c))
      this.$store.dispatch('avd/salvarConfiguracoesAvdModelo', {
        modeloSelecionado: this.modeloSelecionado,
        AvdComportamental: this.AvdComportamental,
        AvdTecnica: this.AvdTecnica
      })
    }
  }
}
</script>

<style>

</style>