<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-stepper alt-labels class="my-4" v-model="step">
          <v-stepper-header class="d-flex flex-column h-auto-custom px-3">
            <!-- Título -->
            <div class="d-flex flex-row mb-0 mt-3">
              <h1>Modelo: {{ modeloSelecionado.titulo }}</h1>
              <div
               class="ml-3 d-flex align-center"
                v-if="
                  $route.params.acao == 'visualizar' ||
                  modeloSelecionado.padrao == 'legiio'
                "
              >
                <h3> - Modo visualização <v-icon>mdi-eye</v-icon></h3>
              </div>
              <div
               class="ml-3 d-flex align-center"
                v-if="
                  $route.params.acao == 'editar' ||
                  modeloSelecionado.padrao == 'legiio'
                "
              >
                <h3> - Modo edição <v-icon>mdi-pencil</v-icon></h3>
              </div>
            </div>

            <!-- Nome dos steppers -->
            <div class="d-flex flex-row justify-space-around">
              <v-stepper-step :complete="step > 1" step="1">
                Dados do modelo
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2">
                Avaliação Comportamental
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 3" step="3">
                Avaliação Técnica
              </v-stepper-step>

            </div>

            <div class="d-md-none d-lg-flex">
              <h2 class="text-center">{{ titulos[step - 1] }}</h2>
            </div>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step dados do modelo -->
            <v-stepper-content step="1">
              <div>
                <div>
                  <h2>Descrição</h2>
                  <p>Dados para identificação do modelo.</p>
                </div>

                <v-card
                  :disabled="
                    $route.params.acao == 'visualizar' ||
                    modeloSelecionado.padrao == 'legiio'
                  "
                  elevation="2"
                  class="container"
                >
                  <v-row>
                    <!-- Titulo -->
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        ref="titulo"
                        label="Título do modelo"
                        placeholder="Escreva o título do modelo"
                        required
                        outlined
                        dense
                        v-model="modeloSelecionado.titulo"
                        data-vv-as="titulo"
                        persistent-hint
                        :hint="errors.first('titulo')"
                        :error="errors.collect('titulo').length ? true : false"
                        v-validate="'required|min:3'"
                        data-vv-validate-on="change"
                        id="titulo"
                        name="titulo"
                      ></v-text-field>
                    </v-col>
                    <!-- Responsavel -->
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <v-select
                        label="Responsável"
                        :items="responsaveis"
                        item-text="nome"
                        item-value="id"
                        placeholder="Selecione um responsável"
                        outlined
                        dense
                        required
                        v-model="modeloSelecionado.id_responsavel"
                        data-vv-as="responsável"
                        persistent-hint
                        :hint="errors.first('responsavel')"
                        :error="errors.collect('responsavel').length ? true : false"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        id="responsavel"
                        name="responsavel"
                      ></v-select>
                    </v-col>
                    <!-- Descricao -->
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        ref="descricao"
                        label="Descrição"
                        outlined
                        dense
                        v-model="modeloSelecionado.descricao"
                        data-vv-as="descrição"
                        persistent-hint
                        :hint="errors.first('descricao')"
                        :error="errors.collect('descricao').length ? true : false"
                        v-validate="'required'"
                        data-vv-validate-on="change"
                        id="descricao"
                        name="descricao"
                      ></v-textarea>
                    </v-col>
                    <!-- Range -->
                    <v-col cols="12" sm="12" md="12">
                      <v-slider
                        class="mx-2"
                        v-model="modeloSelecionado.range"
                        step="1"
                        min="4"
                        max="10"
                        thumb-label
                        ticks
                        persistent-hint
                        hint="Defina o grau máximo da sua escala"
                        label="Tamanho da escala"
                        track-color="orange darken-3"
                      ></v-slider>
                    </v-col>
                  </v-row>
                </v-card>
              </div>

              <!-- Opções -->
              <div class="d-flex flex-column flex-sm-row mt-10">
                <v-btn color="primary" @click="validarInformações()">
                  Continuar
                </v-btn>

                <v-btn class="mx-3" text @click="$router.go(-1)">
                  Fechar
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <AvdComportamental v-if="step == 2" @count="step++" @decrease="step--" />
            </v-stepper-content>
            <v-stepper-content step="3">
              <AvdTecnica v-if="step == 3" @count="step++" @decrease="step--" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import AvdComportamental from "@/modules/avd/modelos/components/AvdComportamental.vue";
import AvdTecnica from "@/modules/avd/modelos/components/AvdTecnica.vue";

export default {
  name: "FormularioModelosView",
  components: {
    AvdComportamental,
    AvdTecnica,
  },
  data: () => ({
    logotipo: logo,
    step: 1,
    requisicao: false,
    titulos: [
      "Dados do modelo",
      "Avaliação Comportamental",
      "Avaliação Técnica",
    ]
  }),
  created() {
    this.$store.dispatch(
      "avd/buscarModeloSelecionado",
      this.$route.params.uuid
    );
  },
  computed: {
    modeloSelecionado: {
      get() {
        return this.$store.getters["avd/getModeloSelecionado"];
      }
    },
    responsaveis: {
      get() {
        return this.$store.getters["avd/getResponsaveis"];
      }
    },
    comportamentos: {
      get() {
        return this.$store.getters["avd/getComportamentos"];
      },
      set(value) {
        return value;
      }
    },
    competencias: {
      get() {
        return this.$store.getters["avd/getCompetencias"];
      }
    },
    loading: {
      get() {
        return this.$store.getters["avd/getLoading"];
      },
      set(value) {
        return value;
      }
    },
  },
  methods: {
    salvar() {
      this.$validator.validateAll().then((result) => {
        if (result === true) {
          const item = this.modeloSelecionado;
          item.url = `avd-modelos/${item.uuid}`;
          item._method = "PUT";
          this.loadingButtonSave = true;
          this.$store
            .dispatch("avd-modelos/salvarModelo", item)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: "Modelo salvo com sucesso.",
                  cor: "green",
                });

                this.$router.go(-1);
              }
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: "Houve um problema em salvar o modelo.",
                cor: "red",
              });
            })
            .finally(() => {
              this.loadingButtonSave = false;
            });
        }
      });
    },
    validarInformações() {
      this.$validator.validateAll().then((result) => {
        if (result === true) {
          return this.step++;
        }
      });
    },
  },
};
</script>

<style scoped>
.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.height-personalizado-card {
  max-height: 300px;
}

.h-auto-custom {
  height: auto;
}
</style>
<style>
.v-stepper__label{
  text-align: center !important;
}
</style>
